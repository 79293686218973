@media only screen and (max-width: 800px) {
    .postContainer {
          display:flex;
          flex-direction: column !important;
        }
    .header{
        flex-wrap: wrap;
    }
    .boardsContainer {
        display: flex !important;
        flex-direction: column !important;
        align-items: center;
    }
    .replyButton {
        flex-shrink: 0;
        flex-wrap: wrap;
    }
    .postText{
        flex-shrink: 0;
        flex-wrap: wrap;
    }
}