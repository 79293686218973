body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #FCF7F8;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 600px) {
  .header {
    width: '400x';
    margin: '8px';
  }
}

